// 气象站点Id
export const STATION_ID = '57655'
// 沅陵国家站
// 经度
export const LON = 113.03
// 纬度
export const LAT = 25.8
export const ADMINCODE = 431222

export const WECHAT_APPID = 'wx7b2afa8ffb6fcaa9'
